<template>
  <div class="home">
    <h2>{{ user?.nickname }}さん</h2>
    <h1>SecuriTalkへようこそ</h1>
    <img alt="Vue logo" src="../assets/site_logo.png" width="100" height="100" />
    <div style="background-color: #f2f2f2; padding: 30px">
      <p>アクセスありがとうございます！</p>
      <p>現在体験できるのは、ケーススタディ「フィッシングメール受信」です。</p>
      <p>以下のリンクから体験してみてください！</p>
      <h4>
        <router-link to="/units-case/1">ケーススタディ「フィッシングメール受信」</router-link>
      </h4>
      <p>その他については誠意実装中です！</p>
      <p>実装をお待ちください。</p>
    </div>
  </div>
</template>
<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  metaInfo: {
    title: "SecuriTalk",
  },
  data() {
    return {
      user: null
    };
  },
  created() {
    const auth0 = useAuth0();
    this.user = auth0.user;
  }
};
</script>
