<template>
  <div class="review">
    <h1 class="mb-4">クリア履歴一覧</h1>
    <div
      v-for="log in clearedlogList"
      :key="log.cleared_id"
      class="list-group"
      style="width: 60%; margin: auto"
    >
      <button
        @click="navigateToReviewChat(log.cleared_id)"
        class="list-group-item list-group-item-action"
      >
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ log.chapter_title }}</h5>
          <small
            >{{ log.clear_timestamp.toLocaleDateString() }}
            {{ log.clear_timestamp.toLocaleTimeString() }}</small
          >
        </div>
        <div class="mb-1">
          <div v-if="log.type === 'case'">ケーススタディ</div>
          <div v-else-if="log.type === 'word'">単語学習</div>
          {{ log.unit_title }}
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";

// レスポンスデータの型を検証し、変換する関数
function transformClearedLog(data) {
  return data.map((item) => ({
    type: String(item.type),
    chapter: Number(item.chapter),
    unit: Number(item.unit),
    chapter_title: String(item.chapter_title),
    unit_title: String(item.unit_title),
    clear_timestamp: new Date(item.clear_timestamp),
    cleared_id: String(item.cleared_id),
  }));
}

export default {
  data() {
    return {
      clearedlogList: [],
    };
  },
  methods: {
    async getClearedLog() {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/review`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // レスポンスデータを検証し、変換してから格納
        this.clearedlogList = transformClearedLog(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    navigateToReviewChat(clearedId) {
      // `review/${clearedId}`にナビゲート
      this.$router.push(`/review/${clearedId}`);
    },
  },
  mounted() {
    this.getClearedLog();
  },
};
</script>
