<template>
  <div>
    <div v-if="(!isAuthenticated && !isLoading) || (isLoading)" class="beforeLogin">
      <beforelogin-componet />
    </div>
    <div v-else-if="isAuthenticated && !isLoading" class="home">
      <home-componet />
    </div>
  </div>
</template>

<script>
import Home from "@/components/HomePage.vue";
import BeforeLogin from "@/components/BeforeLogin.vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  components: {
    "home-componet": Home,
    "beforelogin-componet": BeforeLogin,
  },
  setup() {
    const auth0 = useAuth0();
    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
    };
  },
};
</script>