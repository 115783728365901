<template>
  <div class="w-75 mx-auto">
    <button @click="openModal" :disabled="messages.length < 5">
      ヒントを見る
    </button>
    <h2>
      ケーススタディ{{ unitData.chapter_id }}-{{ unitData.unit_id }}
      {{ unitData.unit_title }}
    </h2>
    <!-- ケーススタディの背景説明文と背景画像 -->
    <h3>{{ unitData.backgroud }}</h3>
    <div class="image-container">
      <img
        :src="require(`@/assets/images/CaseStudy/${unitData.img_path}`)"
        alt="background-img"
      />
    </div>
    <!-- モーダルコンポーネント(ヒント)を表示 -->
    <div class="modal-overlay" v-if="isModalOpen">
      <chat-hint @close="closeModal" :messages="messages"></chat-hint>
    </div>
    <!-- メッセージを表示 -->
    <chat-messages :messages="messages"></chat-messages>
    <!-- 入力フォーム(入力ボックス+音声認識) -->
    <chat-form
      :isSending="isSending"
      :userInput="userInput"
      @sendMessage="sendMessage"
    ></chat-form>
  </div>
</template>

<script>
import ChatHint from "@/components/ChatHint.vue";
import ChatMessages from "@/components/ChatMessages.vue";
import ChatForm from "@/components/ChatForm.vue";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    "chat-hint": ChatHint,
    "chat-messages": ChatMessages,
    "chat-form": ChatForm,
  },
  data() {
    return {
      unitData: {
        chapter_id: 0,
        chapter_title: "string",
        unit_id: 0,
        unit_title: "string",
        question: "string",
        backgroud: "string",
        img_path: "Loading.png",
      },
      userInput: "",
      messages: [],
      isSending: false,
      isModalOpen: false, //モーダルビュー(ヒントポップアップ画面)の状態
    };
  },
  created() {
    // コンポーネントが作成されたときにGETリクエストを送信
    this.fetchData(this.$route.params.chapterId, this.$route.params.unitId);
  },
  methods: {
    async fetchData(chapterId, unitId) {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/chat-case/${chapterId}/${unitId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.unitData = response.data;
        this.addMessage("AI", this.unitData.question, 0);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // 404エラーの場合、404ページへリダイレクト
          this.$router.push({
            name: "404-not-found",
            params: { catchAll: "404-not-found" },
          });
        }
      }
    },
    async sendMessage(userInput) {
      if (!userInput || this.isSending) return; // 送信中の場合は処理を中断
      this.isSending = true; // 送信中フラグを有効化
      // ユーザーのメッセージを追加
      let userAnswer = userInput;
      this.addMessage("User", userAnswer);
      this.userInput = "AIが採点中";

      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/chat-case/${this.$route.params.chapterId}/${this.$route.params.unitId}`,
          { userinput: userAnswer, history: this.messages },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Add AI response to chat
        this.addMessage("AI", response.data.ai_feedback, response.data.score);
        if (response.data.score >= 100) {
          this.isSending = true;
          this.userInput = "クリア済み。おめでとうございます。";
        } else {
          this.isSending = false; // 送信が完了したらフラグを無効化
          this.userInput = ""; // ユーザー入力をクリア
        }
      } catch (error) {
        console.error("Error:", error);
        this.addMessage("AI", "エラーが発生しました。");
        this.isSending = true;
        this.userInput = "エラーが発生しました。";
      }
    },
    addMessage(sender, content, score) {
      const now = new Date();
      const time = now.toLocaleTimeString();
      this.messages.push({
        id: uuidv4(),
        sender,
        content,
        score,
        time,
      });
    },
    openModal() {
      this.isModalOpen = true; // モーダルを表示
    },
    closeModal() {
      this.isModalOpen = false; // モーダルを閉じる
    },
  },
};
</script>

<style>
/* ▼画面サイズ（横幅）が768px以下の場合に適用 */
@media screen and (max-width: 768px) {
  img {
    max-width: 90vw;
  }
}

/* ▼画面サイズ（横幅）が1200px以上の場合に適用 */
@media screen and (min-width: 769px) {
  img {
    max-width: 40vw;
  }
}

/* モーダルビュー(ヒント画面のポップアップ時に使用) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
