<template>
  <header>
    <router-link to="/" class="logo-container">
      <img src="../assets/site_logo_white.png" alt="ロゴ" />
      <span>SecuriTalk</span>
    </router-link>
    <nav v-if="isAuthenticated">
      <ul>
        <li><router-link to="/">ホーム</router-link></li>
        <li><router-link to="/chapters">学習</router-link></li>
        <li><router-link to="/review">復習</router-link></li>
        <!-- 他のリンクを追加 -->
      </ul>
    </nav>
    <div v-if="!isAuthenticated && !isLoading" class="auth-button">
      <button @click="login">ログイン</button>
    </div>
    <div v-else class="auth-button">
      <button @click="logout">ログアウト</button>
    </div>
  </header>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const auth0 = useAuth0();

    return {
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
      login() {
        auth0.loginWithRedirect({
          redirect_uri: window.location.origin,
        });
      },
      logout() {
        auth0.logout({
          logoutParams: { returnTo: window.location.origin },
        });
      },
    };
  },
};
</script>

<style>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  height: 4rem;
  padding: 0.5rem 1rem;
  margin-bottom: 3rem;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* 下線を取り除く */
  color: inherit;
  /* 親要素から色を継承 */
  padding: 0;
}

.logo-container img {
  height: 30px;
  /* ロゴのサイズ調整 */
  padding: 0;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

.auth-button button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.auth-button button:hover {
  background-color: #0056b3;
}
</style>
