<template>
  <div class="chat-container">
    <div v-for="message in messages" :key="message.id">
      <!-- AIからのメッセージの場合 -->
      <div v-if="message.sender === 'AI'" class="AI">
        <div class="chat-box px-3 my-3">
          <div class="chat-left d-flex flex-row">
            <img class="avatar rounded-circle" style="width: 48px; height: 48px; background-color: lightskyblue"
              :src="require(`@/assets/images/SystemIcons/avatar-ai.svg`)" />
            <div class="message-box py-1 px-4 rounded-pill text-start border border-warning" style="width: fit-content">
              <strong>メンタリン</strong><br />
              {{ message.content }}
            </div>
            <time class="mt-auto" style="font-size: small">{{
              message.time
            }}</time>
          </div>
        </div>
      </div>

      <!-- それ以外のユーザーからのメッセージの場合 -->
      <div v-else class="user">
        <div class="chat-box px-3 my-3">
          <div class="chat-left d-flex flex-row-reverse">
            <img class="avatar rounded-circle" style="width: 48px; height: 48px; background-color: lightskyblue"
              :src="require(`@/assets/images/SystemIcons/avatar-user.svg`)" />
            <div class="message-box py-1 px-4 rounded-pill text-start border border-warning"
              style="background-color: lightgreen; width: fit-content">
              <strong>You</strong><br />
              {{ message.content }}
            </div>
            <time class="mt-auto" style="font-size: small">{{
              message.time
            }}</time>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: Array,
  },
};
</script>
