<template>
  <!-- 単語学習の際の表示 -->
  <div v-if="clearedlog.type === 'word'" class="w-50 mx-auto">
    <h4>
      {{ clearedlog.clear_timestamp.toLocaleDateString() }}
      {{ clearedlog.clear_timestamp.toLocaleTimeString() }}<br />
      単語学習 {{ clearedlog.unit_title }}
    </h4>
    <chat-messages :messages="clearedlog.chat_history"></chat-messages>
  </div>
  <!-- ケーススタディの際の表示 -->
  <div v-else-if="clearedlog.type === 'case'" class="w-50 mx-auto">
    <h4>
      {{ clearedlog.clear_timestamp.toLocaleDateString() }}
      {{ clearedlog.clear_timestamp.toLocaleTimeString() }}<br />
      ケーススタディ {{ clearedlog.unit_title }}
    </h4>
    <p>{{ unitCaseData.backgroud }}</p>
    <div class="image-container">
      <img
        :src="require(`@/assets/images/CaseStudy/${unitCaseData.img_path}`)"
        alt="background-img"
      />
    </div>
    <chat-messages :messages="clearedlog.chat_history"></chat-messages>
  </div>
</template>

<script>
import ChatMessages from "@/components/ChatMessages.vue";
import axios from "axios";

// chat_history内の各メッセージを適切な形式に変換する関数
function transformChatHistory(chatHistory) {
  try {
    return chatHistory.map(({ id, sender, content, score, time }) => ({
      id: String(id),
      sender: String(sender),
      content: String(content),
      score: score != null ? Number(score) : null, // scoreがnullの場合はそのままnullを保持
      time: String(time),
    }));
  } catch (e) {
    console.error("Chat history parsing error:", e);
    return []; // パースに失敗した場合は空の配列を返す
  }
}

// レスポンスデータの型を検証し、変換する関数
function transformClearedLog(data) {
  // ここで受け取ったデータが配列の想定だった場合、最初の要素だけを返す
  const item = data[0];
  return {
    type: String(item.type),
    chapter: Number(item.chapter),
    unit: Number(item.unit),
    chapter_title: String(item.chapter_title),
    unit_title: String(item.unit_title),
    clear_timestamp: new Date(item.clear_timestamp),
    chat_history: transformChatHistory(item.chat_history),
    cleared_id: Number(item.cleared_id),
  };
}

export default {
  components: {
    "chat-messages": ChatMessages,
  },
  data() {
    return {
      clearedlog: {},
      unitCaseData: {
        backgroud: "string",
        img_path: "Loading.png",
      },
    };
  },
  mounted() {
    this.fetchClearedlogData(this.$route.params.clearedId);
  },
  methods: {
    async fetchClearedlogData(clearedId) {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/review/${clearedId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // データが空の場合エラー（例: 空のオブジェクト、空の配列等）
        if (response.data.length === 0) {
          throw new Error("EmptyDataError");
        }
        // レスポンスデータを検証し、変換してから格納
        this.clearedlog = transformClearedLog(response.data);
        if (this.clearedlog.type == "case") {
          this.fetchCaseData(this.clearedlog.chapter, this.clearedlog.unit);
        }
      } catch (error) {
        if (
          error.message === "EmptyDataError" ||
          (error.response && error.response.status === 404)
        ) {
          // 404エラーの場合、404ページへリダイレクト
          this.$router.push({
            name: "404-not-found",
            params: { catchAll: "404-not-found" },
          });
        }
      }
    },
    async fetchCaseData(chapterId, unitId) {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/chat-case/${chapterId}/${unitId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.unitCaseData = response.data;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // 404エラーの場合、404ページへリダイレクト
          this.$router.push({
            name: "404-not-found",
            params: { catchAll: "404-not-found" },
          });
        }
      }
    },
  },
};
</script>
