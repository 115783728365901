<template>
  <div>
    <h1>[ケース学習] {{ chapterTitle }}</h1>
    <h3>{{ backgroud }}</h3>
    <div class="image-container">
      <img
        :src="require(`@/assets/images//CaseStudy/${imgPath}`)"
        alt="background-img"
      />
    </div>
    <div v-for="unit in unitsInfo" :key="unit.unit_id" class="mt-3">
      <button type="button" @click="ToChatPage(unit.unit_id)" class="btn btn-outline-success">{{ unit.unit_title }}</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      chapterTitle: "",
      unitsInfo: [],
      backgroud: "",
      imgPath: "Loading.png",
    };
  },
  methods: {
    ToChatPage(unitId) {
      const chapterId = this.$route.params.chapterId;
      // ボタンがクリックされたときに遷移
      this.$router.push(`/chat-case/${chapterId}/${unitId}`);
    },
  },
  async mounted() {
    try {
      const chapterId = this.$route.params.chapterId;
      const token = await this.$auth0.getAccessTokenSilently();
      // APIからデータを取得
      const response = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/units-case/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.chapterTitle = response.data.chapter_title;
      this.unitsInfo = response.data.units_info;
      this.backgroud = response.data.backgroud;
      this.imgPath = response.data.img_path;
    } catch (error) {
      //エラーハンドリング
      if (error.response && error.response.status === 404) {
        // 404エラーの場合、404ページへリダイレクト
        this.$router.push({
          name: "404-not-found",
          params: { catchAll: "404-not-found" },
        });
      }
    }
  },
};
</script>

<style>
/* 画像表示サイズ調整(仮) */
img {
  max-width: 800px;
}
</style>
