<template>
  <div>
    <h1>[単語学習] {{ chapterTitle }}</h1>
    <div v-for="unit in unitsInfo" :key="unit.unit_id">
      <button @click="ToChatPage(unit.unit_id)">{{ unit.unit_title }}</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      chapterTitle: "",
      unitsInfo: [],
    };
  },
  methods: {
    ToChatPage(unitId) {
      const chapterId = this.$route.params.chapterId;
      // ボタンがクリックされたときに遷移
      this.$router.push(`/chat-word/${chapterId}/${unitId}`);
    },
  },
  async mounted() {
    try {
      const chapterId = this.$route.params.chapterId;
      const token = await this.$auth0.getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/units-word/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.chapterTitle = response.data.chapter_title;
      this.unitsInfo = response.data.units_info;
    } catch (error) {
      //エラーハンドリング
      if (error.response && error.response.status === 404) {
        // 404エラーの場合、404ページへリダイレクト
        this.$router.push({
          name: "404-not-found",
          params: { catchAll: "404-not-found" },
        });
      }
    }
  },
};
</script>
