<template>
  <div class="chat-hint">
    <!-- モーダルの内容をここに配置 -->
    <diV v-if="isLoading" class="loading-container">
      <div class="loading-animation"></div>
      <p>AIがヒントを作成中</p>
    </diV>
    <div v-else>
      <h2>これまでの回答に対する評価</h2>
      <p>{{ goodPoint }}</p>
      <h2>ヒント</h2>
      <p>{{ hint }}</p>
    </div>
    <button @click="closeModal">閉じる</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    messages: Array,
  },
  data() {
    return {
      goodPoint: "",
      hint: "",
      isLoading: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close"); // 親コンポーネントにモーダルを閉じるイベントを伝える
    },
    async fetchHintData() {
      const chapterId = this.$route.params.chapterId;
      const unitId = this.$route.params.unitId;
      const chatMode = this.getChatModeFromPath();

      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/chat-hint/${chapterId}/${unitId}`,
          { chat_mode: chatMode, history: this.messages },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.goodPoint = response.data.good_point;
        this.hint = response.data.hint;
      } catch (error) {
        console.error("エラーが発生しました:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getChatModeFromPath() {
      // Vue Routerを使用して現在のパスからchat_modeを判断
      const currentPath = this.$route.path;
      if (currentPath.includes("chat-word")) {
        return "word";
      } else if (currentPath.includes("chat-case")) {
        return "case";
      } else {
        return "";
      }
    },
  },
  mounted() {
    this.fetchHintData();
  },
};
</script>

<style scoped>
.chat-hint {
  background-color: white;
  max-width: 800px;
  min-width: 400px;
  padding: 20px;
  border-radius: 20px;
}
.loading-container {
  display: flex;
  align-items: center;
}

.loading-animation {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
<style scoped></style>
