<template>
  <div class="chapter">
    <h1>チャプター選択</h1>
    <br />
    <div v-for="chapter in chapters" :key="chapter.chapter_id">
      <div class="card mx-auto mb-3 border-dark border-1 shadow-sm" style="max-width: 800px;">
        <div class="row g-0">
          <div class="col-md-4">
            <img :src="require(`@/assets/images/Chapter/${chapter.chapter_img_path}`)" class="img-fluid rounded-start">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title mb-3">{{ chapter.chapter_title }}</h5>
              <p class="card-text">
                <!-- <router-link class="btn btn-outline-success mb-2 w-75" v-if="chapter.has_word_study"
                  :to="'/units-word/' + chapter.chapter_id">
                  単語学習へ進む
                </router-link>
                <br /> -->
                <router-link class="btn btn-outline-success w-75" v-if="chapter.has_case_study"
                  :to="'/units-case/' + chapter.chapter_id">
                  ケーススタディへ進む
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      chapters: [],
    };
  },
  async mounted() {
    try {
      const token = await this.$auth0.getAccessTokenSilently();
      const response = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/chapters`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      this.chapters = response.data;
    } catch (error) {
      console.error("エラー:", error);
      // エラーハンドリングのために適切な処理を追加
    }
  },
};
</script>