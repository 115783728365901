import { createRouter as createVueRouter, createWebHistory } from "vue-router";
import { createAuthGuard } from "@auth0/auth0-vue";
import HomeView from "../views/HomeView.vue";
import ChapterSelectionView from "../views/ChapterSelectionView";
import UnitSelectionWordView from "../views/UnitSelectionWordView";
import ChatPageWordView from "../views/ChatPageWordView";
import UnitSelectionCaseView from "../views/UnitSelectionCaseView";
import ChatPageCaseView from "../views/ChatPageCaseView";
import ReviewView from "../views/ReviewView";
import ReviewChatView from "../views/ReviewChatView";
import NotFoudView from "../views/404NotFoundView";

export function createRouter(app) {
  const routes = [
    {
      //ログイン・ホーム画面
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      //章選択画面
      path: "/chapters",
      name: "chapter-selection",
      component: ChapterSelectionView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //word単元選択画面
      path: "/units-word/:chapterId(\\d+)",
      name: "word-unit-selection",
      component: UnitSelectionWordView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //wordチャット画面
      path: "/chat-word/:chapterId(\\d+)/:unitId(\\d+)",
      name: "chat-word",
      component: ChatPageWordView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //case単元選択画面
      path: "/units-case/:chapterId(\\d+)",
      name: "case-unit-selection",
      component: UnitSelectionCaseView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //caseチャット画面
      path: "/chat-case/:chapterId(\\d+)/:unitId(\\d+)",
      name: "chat-case",
      component: ChatPageCaseView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //復習画面
      path: "/review",
      name: "review",
      component: ReviewView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //復習画面チャット画面
      path: "/review/:clearedId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})",
      name: "review-chat",
      component: ReviewChatView,
      beforeEnter: createAuthGuard(app),
    },
    {
      //404ページ
      path: "/:catchAll(.*)",
      name: "404-not-found",
      component: NotFoudView,
    },
  ];

  const router = createVueRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });
  return router;
}

export default createRouter;
