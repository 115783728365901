<template>
  <div class="chat-from-box d-flex flex-row">
    <button
      @click="toggleListening"
      class="btn p-0 mt-auto"
      style="width: 50px; height: 50px"
    >
      <img
        v-if="isListening"
        class="btn p-0"
        style="width: 48px; height: 48px"
        :src="require(`@/assets/images/SystemIcons/icon_mic_on.svg`)"
        alt="音声認識中"
      />
      <img
        v-else
        class="btn p-0"
        style="width: 48px; height: 48px"
        :src="require(`@/assets/images/SystemIcons/icon_mic_off.svg`)"
        alt="音声認識停止中"
      />
    </button>
    <textarea
      v-model="localUserInput"
      :disabled="isSending"
      class="form-control"
      :placeholder="
        isListening ? '音声認識中...話かけてください' : 'ここに入力'
      "
    ></textarea>
    <button
      @click="sendMessage"
      :disabled="isSending"
      class="btn p-0 mt-auto"
      style="width: 50px; height: 50px"
    >
      <img
        class="btn p-0"
        style="width: 48px; height: 48px"
        :src="require(`@/assets/images/SystemIcons/icon_send.svg`)"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    userInput: String,
    isSending: Boolean,
  },
  data() {
    return {
      localUserInput: this.userInput,
      isListening: false, // 音声認識の状態
    };
  },
  watch: {
    // userInputプロパティの変更を監視
    userInput(newVal) {
      // 親コンポーネントからの変更があった場合、localUserInputを更新
      this.localUserInput = newVal;
    },
  },
  created() {
    this.setupSpeechRecognition();
  },
  methods: {
    sendMessage() {
      // 親にuserInputを渡す
      this.$emit("sendMessage", this.localUserInput);
    },
    setupSpeechRecognition() {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      if (SpeechRecognition) {
        this.recognition = new SpeechRecognition();
        this.recognition.continuous = true; // 連続認識の有効化
        this.recognition.onresult = (event) => {
          const transcript =
            event.results[event.results.length - 1][0].transcript.trim();
          this.handleSpeechResult(transcript);
        };
      } else {
        console.error("このブラウザは音声認識をサポートしていません。");
      }
    },
    toggleListening() {
      if (this.isListening) {
        this.stopListening();
      } else {
        this.startListening();
      }
    },
    startListening() {
      if (this.recognition) {
        this.recognition.start();
        this.isListening = true;
      }
    },
    stopListening() {
      if (this.recognition) {
        this.recognition.stop();
        this.isListening = false;
      }
    },
    handleSpeechResult(transcript) {
      // 音声認識の結果を処理...
      this.localUserInput += transcript; // 例: ユーザー入力フィールドにテキストを設定
    },
  },
};
</script>
<style scoped></style>
